<template>
  <div id="site" class="header-fixed">
    <header class="app-header fixed">
      <img src="@/assets/img/logo.svg" alt="logo" class="site-logo"/>
    </header>
    <div class="main-content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizLayout'
}
</script>

<style lang="scss" scoped>
#site {
  min-height: calc(100vh);
  background: #F4F4F8;

  &.header-fixed {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 70px;
  }

  .menu-icon {
    cursor: pointer;

    img {
      width: 30px;
    }
  }

  .app-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0 50px;
    background: #F4F4F8;
    z-index: 60;

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      transition: all linear 0.1s;
    }

    .site-logo {
      display: block;
      object-position: left center;
      object-fit: scale-down;
    }

    .page-title {
      font-family: Roboto;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #F7A826;
    }

    .btn-back {
      position: relative;
      width: 198px;
      height: 40px;
      background: rgba(255, 255, 255, 0.15);
      border: none;
      border-radius: 3px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;

      .arrow {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        transition: all .2s;
      }

      .icon-close {
        display: none
      }

      &:hover .arrow {
        left: 15px;
      }
    }
  }

  .main-content {
    background: #F4F4F8;
  }
}

@media (max-width: 325px) {
  .menu-wrapper {
    width: 300px;
  }
}
</style>